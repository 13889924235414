import React from 'react';
import { connect } from 'react-redux';
import { getMonthName } from '../../utils/date';
import './CreativeAuthor.scss';

const CreativeAuthor = ({ createdDate, author = {} }) => {
	const postCreatedDate = createdDate ? new Date(createdDate) : '';
	const formattedDate = postCreatedDate
		? `${getMonthName(postCreatedDate.getMonth())}, ${postCreatedDate.getFullYear()}`
		: '';
	return formattedDate && author.username ? (
		<div className="creativeAuthor">
			Posted on {formattedDate} by {author.username}
		</div>
	) : (
		<></>
	);
};

const mapStateToProps = (state) => {
	return {
		createdDate: state.creativeReducer.selectedCreative.createdAt,
		author: state.creativeReducer.selectedCreative.created_by,
	};
};

export default connect(mapStateToProps)(CreativeAuthor);
