import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
// import './VerticalDetails.scss';
import CubeIcon from '../CubeIcon';
import '../Common/mainDetailsContainer.scss';
import mobileScreen from '../../assets/images/hexagontrans.png';
import hexbox from '../../assets/images/innerHexbox.png';
import hexicon from '../../assets/images/hexicon.png';
import { getCdnUrl, getFileType } from '../../utils/string';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import CreativeTypeCardDetails from '../Common/CreativeTypeCardDetails';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

const AdFormatDetails = ({ details }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	let desc = details.description;
	if (desc != '' && desc != undefined) {
		const containsString = details.description.search('\n');
		if (containsString != -1) {
			desc = desc.replace(/(?:\r\n|\r|\n)/g, '\n \n');
		}
	}
	return (
		<>
			<div className="verticalMainContainer mainDetailsContainer">
				<div className="adFormatDetails-Container">
					<div className="ContainerContent">
						<div className="backButtonContent">
							<IconButton
								className="backBtn"
								onClick={() => {
									//fireEvent('Creative Detail Page', 'Click', `Back Button Clicked`);
									navigate(-1);
								}}
							>
								<ArrowBackIcon className="backIcon" />
							</IconButton>
						</div>
						<div className="leftArea">
							<div className="leftContainerHeader">
								<div className="title">VERTICAL SOLUTIONS</div>
								<div className="formatType">{details.name}</div>
								<div className="techDetails">
									<CreativeTypeCardDetails details={details} />
								</div>
							</div>
							{/* <div className="leftContainerDetailsContainer">
								
							</div> */}
						</div>
					</div>
				</div>
				<div className="topIconLiner">
					{/* <div className="iconContainer">
						<img src={hexicon} />
					</div> */}
					<CubeIcon />
				</div>
			</div>
		</>
	);
};
export default AdFormatDetails;
