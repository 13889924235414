import React, { useState } from 'react';
import Icon from '@mui/material/Icon';
import copyClipboard from '../../assets/images/clipboard.svg';
import AddToHomeScreenRoundedIcon from '@mui/icons-material/AddToHomeScreenRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { useNavigate, useLocation } from 'react-router-dom';
import CopyToClipBoard from './../../assets/images/copyToClipBoard.png';
import './CreativePreviewLink.scss';
const CreativePreviewLink = ({ selectedCreative }) => {
	const [copySuccess, setCopySuccess] = useState(false);
	const location = useLocation();
	const copyToClipboard = (id) => {
		navigator.clipboard.writeText(id);
		setCopySuccess((value) => !value);
		//openModalBox();
	};
	return (
		<div className={copySuccess ? 'previewLinkContainer linkCopied' : 'previewLinkContainer'}>
			<img
				src={CopyToClipBoard}
				onClick={() => copyToClipboard(location.origin + '/creative/' + selectedCreative.slug)}
			/>
		</div>
	);
};
export default CreativePreviewLink;
