import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import creativeReducerOld from './creativeOld/CreativeReducer';
import functionalityReducer from './functionality/FunctionalityReducer';
import faqReducer from './faq/FaqReducer';
import searchReducer from './search/SearchReducer';
import verticalReducer from './vertical/VerticalReducer';
import homeReducer from './home/HomeReducer';
import creativeReducer from './creative/CreativeReducer';
const middleware = [thunk];
const rootReducer = combineReducers({
	creativeReducerOld,
	functionalityReducer,
	faqReducer,
	searchReducer,
	verticalReducer,
	homeReducer,
	creativeReducer,
});

export const store = createStore(rootReducer, applyMiddleware(...middleware));
