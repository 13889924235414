import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { connect } from 'react-redux';
import { fireEvent } from '../../utils/tracking';
import CreativeDetailSection from './CreativeDetailSection';
import { fetchCreative, closeDialogModal } from '../../redux/creative/CreativeActions';
import { hideLoader } from './../../redux/functionality/FunctionalityActions';
import './CreativeModal.scss';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/styles';
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import CreativeTagsForModal from './CreativeTagsForModal';
import ModalAuthorize from './ModalAuthorize';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid gray',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));
const CreativeModal = ({
	showCreativeModalBox,
	closeDialogModal,
	loading,
	authorizationFailure,
	hideLoader,
}) => {
	const theme = useTheme();
	// const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		// setOpen(true);
		closeDialogModal();
		fireEvent('Gallery Page', 'Click', `Closed Gallery Modal Box`);
	};
	useEffect(() => { }, [showCreativeModalBox]);
	return authorizationFailure ? (
		<>
			<div
				className={showCreativeModalBox ? 'md-modal md-effect-1 md-show' : 'md-modal md-effect-1'}
				id="modal-1"
			>
				<div className="closeModalIcon authorizeIcon" onClick={handleClose}>
					<CancelIcon />
				</div>
				<div class="md-content">
					<ModalAuthorize />
				</div>
			</div>
			<div className="md-overlay" onClick={handleClose}></div>
		</>
	) : (
		<>
			<div
				className={showCreativeModalBox ? 'md-modal md-effect-1 md-show' : 'md-modal md-effect-1'}
				id="modal-1"
			>
				<div className="closeModalIcon" onClick={handleClose}>
					<CancelIcon />
				</div>
				<div class="md-content">
					<CreativeTagsForModal />
					<CreativeDetailSection />
				</div>
			</div>
			<div className="md-overlay" onClick={handleClose}></div>
		</>
	);
};
const mapDispatchToProps = (dispatch) => {
	return {
		hideLoader: () => dispatch(hideLoader()),
		closeDialogModal: () => dispatch(closeDialogModal()),
	};
};
const mapStateToProps = (state) => {
	return {
		showCreativeModalBox: state.creativeReducer.showCreativeModalBox,
		loading: state.creativeReducer.loading,
		authorizationFailure: state.creativeReducer.authorizationFailure,
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CreativeModal);
