import React, { useState, useEffect } from 'react';
import Switch from '@mui/material';
import Chip from '@mui/material';
import FormGroup from '@mui/material';
import FormControlLabel from '@mui/material';
import FormControl from '@mui/material';
import './TagList.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import { removeQueryStringParameter } from '../../utils/filterDemo';
const TagList = ({ specialTags, creativeCount, totalLength, searchInput, filters }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const origin = `${window.location.protocol}//${window.location.host}`;
	const fullUrl = `${location.pathname}${location.search}`;
	const [noMocks, setNoMocks] = useState(false);
	//console.log('Search Input', searchInput);
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get('postsales');
		//console.log(token);
		if (token != null) {
			setNoMocks(true);
		}
	}, [location.search]);
	const handleChange = () => {
		setNoMocks(!noMocks);
		if (!noMocks) {
			location.search
				? navigate(`${location.href}&postsales`)
				: navigate(`${location.href}?postsales`);
		} else {
			const removedFiltersStringArray = ['postsales'];
			const currentFilterString = location.search.substr(1);
			const filterStringArray = currentFilterString.split('&');
			const updatedFilterStringArray = filterStringArray.filter(
				(filterString) => !removedFiltersStringArray.includes(filterString),
			);
			updatedFilterStringArray.length
				? navigate(`${origin}${location.pathname}?${updatedFilterStringArray.join('&')}`)
				: navigate(`${origin}${location.pathname}`);
		}
	};

	const handleClick = (x) => {
		const specialtag = encodeURI(`specialtags=${x}`);
		const currentFilterString = location.search.substr(1);
		const filterStringArray = currentFilterString.split('&');
		const updatedFilterStringArray = filterStringArray.filter(
			(filterString) => filterString != specialtag,
		);

		//console.log("tagList handleClick")
		//const newParamsList = removeQueryStringParameter(location.search, 'specialtags', x); //removeURLParameter("specialtags", location.search);
		//navigate(`${location.origin}${location.pathname}${newParamsList}`);
		updatedFilterStringArray.length
			? navigate(`${location.pathname}?${updatedFilterStringArray.join('&')}`)
			: navigate(`${location.pathname}`);
	};
	const handleRequestDelete = (chipMap) => {
		//console.log("inside it", chipMap);
	};
	const removeSearchInput = () => {
		const searchQueryDtring = encodeURI(`search=${searchInput}`);

		const currentFilterString = location.search.substr(1);
		const filterStringArray = currentFilterString.split('&');
		//console.log(filterStringArray);
		const updatedFilterStringArray = filterStringArray.filter(
			(filterString) => filterString != searchQueryDtring,
		);

		updatedFilterStringArray.length
			? navigate(`${location.pathname}?${updatedFilterStringArray.join('&')}`)
			: navigate(`${location.pathname}`);
	};
	const newSpecialTags = [];
	for (let i = 0; i < specialTags.length; i++) {
		newSpecialTags.push(specialTags[i]);
	}

	return (
		<>
			<div className="NoMockMainContainer">
				{/* <div className="NoMockContainer">
					{Object.keys(filters).length > 0 && newSpecialTags.length > 0 ? (
						<>
							{newSpecialTags.map(function(tag, i) {
								return (
									<div class="md-chip">
										<span>{tag}</span>
										<button type="button" class="md-chip-remove" onClick={() => handleClick(tag)}></button>
									</div>
								);
							})}
						</>
					) : (
						<></>
					)}
					{Object.keys(filters).length > 0 && totalLength > 0 ? (
						<div class="md-chip">
							<span>
								Showing {creativeCount} of {totalLength} results
							</span>
						</div>
					) : (
						<></>
					)}
					{searchInput.length > 0 ? (
						<div class="md-chip">
							<span>{searchInput}</span>
							<button type="button" class="md-chip-remove" onClick={() => removeSearchInput()}></button>
						</div>
					) : (
						<></>
					)}
				</div> */}
				{Object.keys(filters).length > 0 && totalLength > 0 ? (
					<div className="NoMockContainerShowing">
						<div class="md-chip1">
							<span>
								Showing {creativeCount} of {totalLength} results
							</span>
						</div>
					</div>
				) : (
					<></>
				)}
				{Object.keys(filters).length > 0 && newSpecialTags.length > 0 ? (
					<div className="NoMockContainer">
						{newSpecialTags.map(function (tag, i) {
							return (
								<div class="md-chip">
									<span>{tag}</span>
									<button type="button" class="md-chip-remove" onClick={() => handleClick(tag)}></button>
								</div>
							);
						})}
					</div>
				) : (
					<></>
				)}

				{searchInput.length > 0 ? (
					<div className="NoMockContainer">
						<div class="md-chip">
							<span>{searchInput}</span>
							<button type="button" class="md-chip-remove" onClick={() => removeSearchInput()}></button>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		specialTags: state.creativeReducer.specialTags,
		creativeCount: state.creativeReducer.creativeCount,
		totalLength: state.creativeReducer.backupCreatives.length,
		searchInput: state.creativeReducer.searchInput,
		filters: state.creativeReducer.filters,
	};
};

export default connect(mapStateToProps, null)(TagList);
