import React from 'react';
import {Autocomplete,TextField} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { fireEvent } from '../../utils/tracking';
import './FilterType.scss';

const FilterType = ({ name, filterItems, selectedFilters = [] }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const origin = `${window.location.protocol}//${window.location.host}`;
	
  	// Or if you want to get the full URL (including the pathname)
  	const fullUrl = `${location.pathname}${location.search}`;
	// console.log("fullUrl",fullUrl)
	// console.log("origin",origin)
	// console.log("location",location)
	return (
		<Autocomplete
			multiple
			limitTags={1}
			size="small"
			id={`filter${name}`}
			options={filterItems.map((filterItem) => filterItem.name)}
			renderInput={(params) => (
				<TextField {...params} variant="outlined" label={name} className="filterTextField" />
			)}
			className="filterType"
			filterSelectedOptions
			autoHighlight
			value={selectedFilters}
			onChange={(e, newValue = []) => {
				//console.log("newvalue",newValue)
				if (newValue.length > selectedFilters.length) {
					// Filter Added
					const [addedFilter] = newValue.filter((filter) => !selectedFilters.includes(filter));
					fireEvent('Home Page', 'Click', `${name} - ${addedFilter} Filter Applied`);
					//console.log("location",window.location.href)
					location.search
						? navigate(`${fullUrl}&${name}=${addedFilter}`)
						: navigate(`${fullUrl}?${name}=${addedFilter}`);
				} else {
					// Filter Removed
					const removedFilters = selectedFilters.filter((filter) => !newValue.includes(filter));
					const removedFiltersStringArray = removedFilters.map((removedFilter) =>
						encodeURI(`${name}=${removedFilter}`),
					);

					const currentFilterString = location.search.substr(1);
					const filterStringArray = currentFilterString.split('&');
					const updatedFilterStringArray = filterStringArray.filter(
						(filterString) => !removedFiltersStringArray.includes(filterString),
					);
					//console.log("updatedFilterStringArray",updatedFilterStringArray)
					updatedFilterStringArray.length
						? navigate(`${location.pathname}?${updatedFilterStringArray.join('&')}`)
						: navigate(`${fullUrl}`);
				}
			}}
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		selectedFilters: state.creativeReducer.selectedFilters[ownProps.name],
	};
};

export default connect(mapStateToProps)(FilterType);
