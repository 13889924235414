import React from 'react';
// import SearchBar from './SearchBar';
import './HomeBanner.scss';
import LabIcons from './../../assets/images/lab_icons.png';
import Grid from '@mui/material/Grid';

const HomeBanner = () => (
	<div className="fhomeBanner">
		<div className="content">
			<div className="heading">FORMULATING MOBILE MAGIC</div>
		</div>
	</div>
);

export default HomeBanner;
