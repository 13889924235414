import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Router} from 'react-router-dom';
import { Provider } from 'react-redux';
// import ScrollToTop from './ScrollToTop';
import Home from '../pages/Home';
// import CreativesOld from '../pages/CreativesOld';
import CreativeDetail from '../pages/CreativeDetail';
import FunctionalitiesList from '../pages/Functionalities';
import Faqs from '../pages/Faqs';
import LoginRedirect from '../pages/LoginRedirect';
import NotFound from '../pages/NotFound';
import Footer from './Footer';
import { pageView } from '../utils/tracking';
import AdFormatDetails from '../pages/AdFormatDetails';
import Creatives from '../pages/Creatives';
import VerticalDetails from '../pages/VerticalDetails';
import VerticalsPage from '../pages/Verticals';
import { Routes,Route,Navigate } from 'react-router-dom';
import { ThemeProvider,createTheme } from '@mui/material/styles';
const theme = createTheme({
	palette: {
	  background: {
		default: '#f5f5f5', // Add a default background color or theme here
	  },
	  // other theme settings
	},
  });
const AppRouter = (props) => {
	/*
	useEffect(() => {
		const unsubscribeHistory = globalHistory.listen(({ location }) => {
			pageView();
		});
		return () => unsubscribeHistory();
	}, []);*/
	// const location = useLocation();

//   useEffect(() => {
//     const pageView = () => {
//       console.log('Page view logged');
//       // Your custom analytics or logic here
//     };

//     pageView(); // Trigger when the component mounts or location changes
//   }, [location]);

	return (
			<Provider store={props.store}>
			{/* <Router primary={false}>
			
				<Home path="/" />
				

				<CreativeDetail path="/creative/:creativeId" />
				<FunctionalitiesList path="/functionalities" />
				<Creatives path="/gallery" />
				
				<Faqs path="/faqs" />
				<LoginRedirect path="/connect/microsoft/redirect" />
				<AdFormatDetails path="/functionality/:functionalityId" />
				<NotFound default />

				<VerticalDetails path="/vertical/:verticalId" />
				<VerticalsPage path="/verticals" />
				
			</Router> */}
			<ThemeProvider theme={theme}>
				<Routes>
					<Route  exact path="/" element ={<Home/>} />
					<Route path="/gallery" element={<Creatives/>} />
					<Route path="/functionalities" element={<FunctionalitiesList/>} />
					<Route path="/verticals" element={<VerticalsPage/>} />
					<Route path="/vertical/:verticalId" element={<VerticalDetails/>} />
					<Route path="/functionality/:functionalityId" element={<AdFormatDetails/>} />
					<Route path="/connect/microsoft/redirect" element={<LoginRedirect/>} />
				</Routes>	
			</ThemeProvider>
			<Footer />
		</Provider>
	);
};

AppRouter.propTypes = {
	store: PropTypes.object.isRequired,
};

export default AppRouter;
