import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ReactMarkdown from 'react-markdown';
import { fireEvent } from '../../utils/tracking';
import './Faq.scss';

const Faq = ({ faq, key }) => {
	const id = key;
	return (
		<Accordion className="faq">
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				onClick={() => fireEvent('Faqs Page', 'Click', `Faq ${faq.id} Clicked`)}
			>
				<p className="faqQuestion">{faq.question}</p>
			</AccordionSummary>
			<AccordionDetails>
				<p className="faqAnswer">{faq.answer}</p>
			</AccordionDetails>
		</Accordion>
		// <div className="faq-drawer">
		// 	<input className="faq-drawer__trigger" id="name`{id}`" type="checkbox" /><label className="faq-drawer__title" for="faq-drawer">{faq.question}</label>
		// 	<div className="faq-drawer__content-wrapper">
		// 		<div className="faq-drawer__content">
		// 			<p>
		// 				{faq.answer}
		// 			</p>
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default Faq;
