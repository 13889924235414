import React, { useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import Loader from '../components/Loader';
import Error from '../components/Error';
import { connect } from 'react-redux';
import { fetchVertical } from '../redux/vertical/VerticalActions';
import VerticalContainer from '../components/Verticals/VerticalContainer';
import CreativeModal from '../components/Creative/CreativeModal';
import { useParams } from 'react-router-dom';
const VerticalDetails = ({ verticalId, fetchVertical, error, loading, selectedVertical }) => {
	const verticalIds = useParams();
	
	verticalId=verticalIds.verticalId
	useEffect(() => {
		fetchVertical(verticalId);
	}, [fetchVertical, verticalIds]);
	return error ? (
		<>
			<PageTitle pageName={'Vertical Details'} />
			<Header button="back" />
			<Error error={error} />
		</>
	) : loading ? (
		<>
			<Loader open={loading} />
			<PageTitle pageName={''} />
			<Header button="back" />
		</>
	) : (
		// ) : loading ? (
		// 	<>
		// 		<Loader open={loading} />
		// 		<PageTitle pageName={'Vertical Details'} />
		// 		<Header button="back" />
		// 	</>
		// )
		<>
			<Loader open={loading} />
			<CreativeModal />
			<PageTitle />
			<Header button="back" />
			<VerticalContainer details={selectedVertical} />
		</>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.verticalReducer.loading,
		selectedVertical: state.verticalReducer.selectedVertical,
		error: state.verticalReducer.error,
	};
};
export default connect(mapStateToProps, { fetchVertical })(VerticalDetails);
