import React, { useState, useEffect } from 'react';
import Switch from '@mui/material';
import Chip from '@mui/material';
import FormGroup from '@mui/material';
import FormControlLabel from '@mui/material';
import FormControl from '@mui/material';
import { fireEvent } from '../../utils/tracking';
import './NoMockSwitch.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const NoMockSwitch = ({ specialTags }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [noMocks, setNoMocks] = useState(false);
	const origin = `${window.location.protocol}//${window.location.host}`;
	
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get('postsales');
		// console.log('token', noMocks);
		if (token != null) {
			setNoMocks(true);
		}
	}, [location.search]);
	const handleChange = () => {
		setNoMocks(!noMocks);
		if (!noMocks) {
			location.search
				? navigate(`${location.pathname}${location.search}&postsales`)
				: navigate(`${location.pathname}${location.search}?postsales`);
			fireEvent('Gallery Page', 'Click', `Show Mocks Triggered`);
		} else {
			const removedFiltersStringArray = ['postsales'];
			const currentFilterString = location.search.substr(1);
			const filterStringArray = currentFilterString.split('&');
			const updatedFilterStringArray = filterStringArray.filter(
				(filterString) => !removedFiltersStringArray.includes(filterString),
			);
			updatedFilterStringArray.length
				? navigate(`${location.pathname}?${updatedFilterStringArray.join('&')}`)
				: navigate(`${location.pathname}`);
			fireEvent('Gallery Page', 'Click', `Hide Mocks Triggered`);
		}
	};

	const handleClick = (x) => {
		const specialtag = encodeURI(`specialtags=${x}`);
		const currentFilterString = location.search.substr(1);
		const filterStringArray = currentFilterString.split('&');
		const updatedFilterStringArray = filterStringArray.filter(
			(filterString) => filterString != specialtag,
		);

		//const newParamsList = removeQueryStringParameter(location.search, 'specialtags', x); //removeURLParameter("specialtags", location.search);
		//navigate(`${location.origin}${location.pathname}${newParamsList}`);
		updatedFilterStringArray.length
			? navigate(`${location.pathname}?${updatedFilterStringArray.join('&')}`)
			: navigate(`${location.pathname}`);
	};
	const handleRequestDelete = (chipMap) => {
		//console.log("inside it", chipMap);
	};

	const newSpecialTags = [];
	for (let i = 0; i < specialTags.length; i++) {
		console.log(specialTags[i]);
		newSpecialTags.push(specialTags[i]);
	}

	return (
		<>
			{/* <div className="NoMockContainerRight">
				<FormControl component="fieldset">
					<FormGroup aria-label="position" row className="switch">
						<FormControlLabel
							value="end"
							control={<Switch color="primary" />}
							label="Hide Mocks"
							labelPlacement="end"
							checked={noMocks}
							onChange={() => handleChange()}
						/>
					</FormGroup>
				</FormControl>
			</div> */}
			<label className="toggleSwitch nolabel">
				<input type="checkbox" checked={!noMocks} onChange={() => handleChange()} />
				<span>
					<span>Show Mocks</span>
					<span>Hide Mocks</span>
				</span>
				<a></a>
			</label>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		specialTags: state.creativeReducer.specialTags,
	};
};

export default connect(mapStateToProps, null)(NoMockSwitch);
