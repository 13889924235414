import React from 'react';
// import SearchBar from './SearchBar';
import './HomeBanner.scss';
import { Link } from 'react-router-dom';
import LabIcons from './../../assets/images/lab_icons.png';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import mobileScreen from '../../assets/images/hexagontrans.png';
import homeScreenHex from '../../assets/images/homeheaderimage.png';
import inMobiIcon from '../../assets/images/logowhite.png';
import CreativeGalleryText from '../../assets/images/creativegallery.png';
import VisitGallery from '../../assets/images/visitgallerybtn.png';

const HomeBanner = () => (
	<div className="banner">
		<div className="content">
			{/* <div className="heading">FORMULATING MOBILE MAGIC</div> */}
			{/* <img src={LabIcons} alt="Lab Icons" className="labIcons" /> */}
			<div className="topDetails">
				<div className="topBannerContainer">
					<div className="hexagonbg">
						<img src={homeScreenHex} />
					</div>
				</div>
				<div className="galleryIcon">
					{/* <div className="icon">
						<img src={inMobiIcon} />
					</div> */}
					{/* <div className="siteName">
						<img src={CreativeGalleryText} />
					</div> */}
					{/* <Button variant="contained" className="button" href="/gallery">
						VISIT GALLERY
					</Button> */}
					<Link to="/gallery?postsales" className="button">
						<img src={VisitGallery} />
					</Link>
				</div>
			</div>

			{/* <div className="galleryViewContainer">
				<Button variant="contained" className="button" href="/functionalities">
					View Complete Creative Gallery
				</Button>
			</div> */}
		</div>
	</div>
	// 	<Grid container spacing={3} className="banner">
	// 		<Grid item xs={12}>
	// 			<div className="heading">FORMULATING MOBILE MAGIC</div>
	// 		</Grid>
	// 		<Grid item xs={12}>
	// 			<img src={LabIcons} alt="Lab Icons" className="labIcons" />
	// 		</Grid>
	// 	</Grid>
);

export default HomeBanner;
