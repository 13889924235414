import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardChip from './../Functionality/CardChip';
import { Link } from 'react-router-dom';
import { checkLength, getCdnUrl } from '../../utils/string';
import { fireEvent } from '../../utils/tracking';
import { connect } from 'react-redux';
import { selectFunctionality } from '../../redux/functionality/FunctionalityActions';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import CreativeTypeCard from './../Common/CreativeTypeCard';
// import './VerticalCard.scss';
const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));
const VerticalCard = ({ vertical, selectVertical }) => {
	const classes = useStyles();
	const handleClick = () => {
		fireEvent('Docs Page', 'Click', `${vertical.name} Clicked`);
		//selectFunctionality();
	};

	return (
		// <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className="verticalCardContainer">
		// 	<Paper className="cardContainer creative-card" square variant="outlined">
		// 		<Link to={`/vertical/${vertical.slug}`} onClick={handleClick}>
		// 			<div className="border boxContainer" title={vertical.name}>
		// 				<div className="creative-card-content">
		// 					<div className="flex-column flex-space-between">
		// 						<div className="card-icon flex-row flex-center">
		// 							<div className="verticalIcon">
		// 								<img src={vertical.icon ? getCdnUrl(vertical.icon.url) : ''} alt="Creative Icon" />
		// 							</div>
		// 						</div>
		// 					</div>
		// 					<div className="flex-column flex-space-between">
		// 						<div className="heading">{checkLength(vertical.name, 24, 20)}</div>
		// 					</div>
		// 					{/* <div className="card-description">{checkLength(vertical.description, 80, 70)}</div> */}

		// 					<div className="flex-column flex-space-between">
		// 						<div className="card-cta">View</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 			<div className="borderBottom"></div>
		// 			{/* <Card className="creative-card" title={functionality.name}>
		// 				<CardContent className="flex-column creative-card-content">

		// 				</CardContent>
		// 			</Card> */}
		// 		</Link>
		// 	</Paper>
		// </Grid>
		<CreativeTypeCard details={vertical} type="vertical" />
	);
};

export default VerticalCard;
