import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardChip from './CardChip';
import { Link } from 'react-router-dom';
import { checkLength, getCdnUrl } from '../../utils/string';
import { fireEvent } from '../../utils/tracking';
import { connect } from 'react-redux';
import { selectFunctionality } from '../../redux/functionality/FunctionalityActions';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';

import CreativeTypeCard from './../Common/CreativeTypeCard';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));
const FunctionalityCard = ({ functionality, selectFunctionality }) => {
	// const classes = useStyles();
	// const handleClick = () => {
	// 	fireEvent('Docs Page', 'Click', `${functionality.name} Clicked`);
	// 	//selectFunctionality();
	// };

	return (
		<CreativeTypeCard details={functionality} type="functionality" />
		// <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className="functionalityCardContainer">
		// 	<Paper className="cardContainer creative-card" square variant="outlined">
		// 		<Link to={`/functionality/${functionality.slug}`} onClick={handleClick}>
		// 			<CreativeTypeCard details={functionality} />
		// 			<div className="border boxContainer" title={functionality.name}>
		// 				<div className="creative-card-content">
		// 					<div className="flex-column flex-space-between">
		// 						<div className="card-icon flex-row flex-center">
		// 							{/* <div className="functionalityIcon">
		// 								<img
		// 									src={functionality.icon ? getCdnUrl(functionality.icon.url) : ''}
		// 									alt="Creative Icon"
		// 								/>
		// 							</div> */}
		// 							<div className="verticalIcon">
		// 								{/* <img src={hexbox} alt="Creative Icon" className="outerBox" /> */}
		// 								<div className="hexContainer">
		// 									<img src={functionality.icon ? getCdnUrl(functionality.icon.url) : ''} alt="Creative Icon" />
		// 								</div>
		// 							</div>
		// 						</div>
		// 					</div>
		// 					<div className="flex-column flex-space-between">
		// 						<div className="heading">{checkLength(functionality.name, 24, 20)}</div>
		// 					</div>

		// 					{/* <div className="card-description">{checkLength(functionality.description, 80, 70)}</div> */}

		// 					{/* <div className="flex-column flex-space-between">
		// 						<div className="card-cta">View</div>
		// 					</div> */}
		// 				</div>
		// 			</div>
		// 			<div className="borderBottom"></div>
		// 			{/* <Card className="creative-card" title={functionality.name}>
		// 				<CardContent className="flex-column creative-card-content">

		// 				</CardContent>
		// 			</Card> */}
		// 		</Link>
		// 	</Paper>
		// </Grid>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		selectFunctionality: () => dispatch(selectFunctionality(ownProps.functionality.slug)),
	};
};

export default connect(null, mapDispatchToProps)(FunctionalityCard);
